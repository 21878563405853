<template>
  <v-card>
    <v-container>
      <v-card-title>
        <span class="pl-1 primary--text">{{ formEditTitle }}</span>
      </v-card-title>

      <v-card-text>
        <v-form
          v-model="isFormValid"
          ref="form"
          form="form"
          id="form"
          @submit.prevent="saveEdit()"
        >
          <v-row>
            <v-col cols="12" md="12" class="py-0">
              <v-text-field
                type="text"
                outlined
                dense
                :rules="rules.required.concat([rules.maxLength(codigo, 3)])"
                label="Código"
                v-model="codigo"
                autocomplete="off"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="12" class="py-0">
              <v-textarea
                v-model="descripcion"
                auto-grow
                outlined
                dense
                :rules="[rules.maxLength(descripcion, 250)]"
                label="Descripción"
                rows="1"
              ></v-textarea>
            </v-col>
            <v-col cols="12" md="6" class="py-0">
              <v-select
                v-model="signoSelected"
                :items="signos"
                label="Signo"
                item-value="id"
                item-text="value"
                :rules="signoSelected != null ? [] : rules.required"
                outlined
                dense
              >
              </v-select>
            </v-col>
            <v-col cols="12" md="6" class="py-0">
              <v-select
                v-model="tipoConceptoAFIPSelected"
                :items="tiposConceptosAFIP"
                label="Aplicar signo en"
                item-value="value"
                item-text="conceptoNom"
                :rules="rules.required"
                outlined
                dense
              >
              </v-select>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn outlined @click="closeModal"> Cancelar </v-btn>
        <v-btn
          type="submit"
          :disabled="!isFormValid"
          form="form"
          color="primary"
        >
          Guardar
        </v-btn>
      </v-card-actions>
    </v-container>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import rules from "@/utils/helpers/rules";
import enums from "@/utils/enums/index.js";
import { mask } from "vue-the-mask";

export default {
  name: "EditConceptoAFIP",
  props: {
    concepto: {
      type: Object,
      required: false,
    },
  },
  directives: { mask },
  data: () => ({
    isFormValid: false,
    formEditTitle: enums.titles.EDITAR_CONCEPTO_AFIP,
    formNewTitle: enums.titles.NUEVO_CONCEPTO_AFIP,
    rules: rules,
    codigo: null,
    descripcion: null,
    signoSelected: null,
    signos: [
      {
        id: -1,
        value: "-1",
      },
      {
        id: 0,
        value: "0",
      },
      {
        id: 1,
        value: "1",
      },
    ],
    tipoConceptoAFIPSelected: null,
    tiposConceptosAFIP: [],
  }),
  created() {
    if (this.concepto != null) {
      this.setConceptoAFIP();
    } else {
      this.newConceptoAFIP();
    }
    this.setTiposConceptosAFIP();
  },
  methods: {
    ...mapActions({
      setAlert: "user/setAlert",
      postConceptoAFIP: "aportes/postConceptoAFIP",
      getTiposConceptosAFIP: "aportes/getTiposConceptosAFIP",
    }),
    closeModal() {
      this.$emit("closeAndReload");
    },
    async setTiposConceptosAFIP() {
      const conceptos = await this.getTiposConceptosAFIP();
      this.tiposConceptosAFIP = conceptos;
    },
    setConceptoAFIP() {
      this.codigo = this.concepto.codigo;
      this.descripcion = this.concepto.descripcion;
      this.signoSelected = this.concepto.signo;
      this.tipoConceptoAFIPSelected = this.concepto.concepto;
    },
    async newConceptoAFIP() {
      this.formEditTitle = this.formNewTitle;
    },
    async saveEdit() {
      const data = {
        conceptoId: this.concepto != null ? this.concepto.conceptoId : 0,
        codigo: this.codigo,
        descripcion: this.descripcion,
        signo: this.signoSelected,
        concepto: this.tipoConceptoAFIPSelected
      };
      const response = await this.postConceptoAFIP(data);
      if (response.status === 200) {
        this.setAlert({ type: "success", message: "Guardado con éxito." });
        this.closeModal();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
